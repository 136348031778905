import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { Alert, Button, DatePicker, SearchInput } from '../../../components';
import { useChangePagination } from '../../../hooks';
import { useExportXmlMutation } from '../../../services/dockingApi';
import { useGetStopoveresGroupbyDockingStatisticsQuery } from '../../../services/stopoverApi';
import { StopoverGroupbyDockingStatisticsType } from '../../../types';
import { createDateString, downloadFile } from '../../../utils/utils';
import { SectionHeader } from '../styles';
import { DockingReportDrawer } from './dockingReportDrawer/DockingReportDrawer';
import { DockingsTable } from './dockingsTable/DockingsTable';

export type DockingsFiltersType = {
  initial_date_report?: string | null;
  final_date_report?: string | null;
  code_or_vessel__name?: string;
  page?: number;
};

export function DockingsSection() {
  const { onChangePagination, queryPage } = useChangePagination();

  const [dockingFilters, setDockingFilters] = useState<DockingsFiltersType>({
    page: queryPage,
  } as DockingsFiltersType);

  function isAllDockingSelected() {
    if (!dockingsData || !dockingsData.results) {
      return false;
    }

    return dockingsData.results.every((item) =>
      dockingsIdToEdit.includes(item.docking__id)
    );
  }

  useEffect(() => {
    setDockingFilters((prev) => ({ ...prev, page: queryPage }));
  }, [queryPage]);

  const [dockingsIdToEdit, setDockingsIdToEdit] = useState<number[]>([]);

  const [isVisibleDockingReportDrawer, setIsVisibleDockingReportDrawer] =
    useState(false);

  const { data: dockingsData, isFetching: isFetchingDockingsData } =
    useGetStopoveresGroupbyDockingStatisticsQuery(dockingFilters);

  useEffect(() => {
    if (!isFetchingDockingsData) {
      if (isAllDockingSelected()) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    }
  }, [dockingsData]);

  const [exportXml, { isLoading: isLoadingExportXml }] = useExportXmlMutation();

  const [selectedDocking, setSelectedDocking] = useState(
    {} as StopoverGroupbyDockingStatisticsType
  );

  const [checkedAll, setCheckedAll] = useState(false);

  function onSelectViewDocking(docking: StopoverGroupbyDockingStatisticsType) {
    setSelectedDocking(docking);
    setIsVisibleDockingReportDrawer(true);
  }

  function onSearch(value: string) {
    if (value !== '') {
      setDockingFilters({ code_or_vessel__name: value });
    } else {
      setDockingFilters((prev) => ({ ...prev, code_or_vessel__name: '' }));
    }
  }

  function onChangeDatePicker(dates: any) {
    if (dates === null) {
      setDockingFilters((prev) => ({
        code_or_vessel__name: prev.code_or_vessel__name,
      }));
      return;
    }
    let filteredDates = {};
    if (dates && dates.length > 0 && dates[0]) {
      filteredDates = {
        ...filteredDates,
        initial_date_report: createDateString(dates[0], true),
      };
    }
    if (dates && dates.length > 1 && dates[1]) {
      filteredDates = {
        ...filteredDates,
        final_date_report: createDateString(dates[1], true),
      };
    }
    setDockingFilters((prev) => ({ ...prev, ...filteredDates }));
    setDockingsIdToEdit([]);
  }

  function handleCheckDocking(isChecked: boolean, dockingId: number) {
    if (isChecked) {
      setDockingsIdToEdit((prev) => [...prev, dockingId]);
    } else {
      setDockingsIdToEdit((prev) => [
        ...prev.filter((item) => item !== dockingId),
      ]);
      setCheckedAll(false);
    }
  }

  function handleCheckedAll(checked: boolean) {
    if (checked) {
      setCheckedAll(true);
      setDockingsIdToEdit((prev) => [
        ...prev,
        ...(dockingsData?.results
          .filter((item) => !prev.includes(item.docking__id))
          .filter((item) => item.docking_place.antaq_code !== null)
          .flatMap((item) => item.docking__id) || []),
      ]);
    } else {
      setCheckedAll(false);
      setDockingsIdToEdit(
        dockingsIdToEdit.filter((id) => {
          return !dockingsData?.results
            .flatMap((item) => item.docking__id)
            .includes(id);
        })
      );
    }
  }

  async function handleGenerateXml() {
    let filters = {};
    if (dockingsIdToEdit.length > 0) {
      filters = {
        list_of_docking_ids: dockingsIdToEdit,
      };
    } else if (
      dockingFilters.initial_date_report &&
      dockingFilters.final_date_report
    ) {
      filters = {
        initial_date_report: dockingFilters.initial_date_report,
        final_date_report: dockingFilters.final_date_report,
      };
    }
    await exportXml(filters).then((response) => {
      if ('data' in response) {
        downloadFile(
          response.data,
          `XmlAntaq_${moment().format('YYYYMMDDHHmmss')}.xml`
        );
      }
    });
  }

  return (
    <>
      <SectionHeader style={{ flexDirection: 'column', gap: '15px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
              marginInlineEnd: '10px',
            }}
          >
            <SearchInput
              size="large"
              allowClear
              placeholder="Pesquisar embarcação/atracação"
              style={{ width: '400px' }}
              onChange={debounce((evt) => onSearch(evt.target.value), 500)}
            />
            <div>
              <strong>{dockingsIdToEdit.length} </strong>
              {dockingsIdToEdit.length === 1 ? (
                <span>atracação selecionada</span>
              ) : (
                <span>atracações selecionadas</span>
              )}
            </div>
            <Alert
              type="warning"
              message="Selecione as atracações desejadas para ativar a geração do XML.
              É possível selecionar um intervalo de atracações ao filtrar por data."
            />
          </div>
          <Button
            type="primary"
            onClick={() => handleGenerateXml()}
            disabled={dockingsIdToEdit.length === 0}
            loading={isLoadingExportXml}
          >
            Gerar XML
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 'auto',
            marginBottom: '20px',
          }}
        >
          <span style={{ width: '240px' }}>
            Filtro por período de desatracação:{'   '}
          </span>
          <DatePicker
            allowClear
            onChange={onChangeDatePicker}
            minuteStep={1}
            isRangePicker
            showTime={false}
            format={['DD/MM/YYYY']}
            style={{ width: '240px' }}
          />
        </div>
      </SectionHeader>
      <DockingsTable
        rowKey="docking__id"
        dataSource={dockingsData?.results || []}
        onChangePagination={onChangePagination}
        total={dockingsData?.count}
        isLoading={isFetchingDockingsData}
        queryPage={queryPage}
        showSizeChanger={false}
        onOpenDrawer={onSelectViewDocking}
        onSelectItem={handleCheckDocking}
        dockingsIdToEdit={dockingsIdToEdit}
        checkedAll={checkedAll}
        onCheckedAll={handleCheckedAll}
        itemsPerPage={dockingsData?.results.length}
      />
      <DockingReportDrawer
        isVisible={isVisibleDockingReportDrawer}
        setIsVisible={setIsVisibleDockingReportDrawer}
        selectedDocking={selectedDocking}
        setSelectedDocking={setSelectedDocking}
      />
    </>
  );
}
