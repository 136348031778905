import { DeleteOutlined } from '@ant-design/icons';
import { Col, Divider, FormInstance, Row, message } from 'antd';
import _, { debounce, isEmpty } from 'lodash';
import { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';

import {
  Alert,
  Button,
  FormItem,
  FormItemDatePicker,
  FormItemInput,
  FormItemInputNumber,
  FormItemSelect,
  FormItemTextArea,
  FormItemsCheckbox,
  Select,
  TableFormList,
} from '../../../components';
import {
  useGetCargoTypeQuery,
  useGetProductGroupsQuery,
} from '../../../services/cargoApi';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { useGetBerthsQuery } from '../../../services/dockingPlacesApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import {
  ChargeType,
  FareType,
  PriceTableItemType,
} from '../../../typesFinancial';
import {
  createDateStringPtBr,
  formatNumberToLocale,
  removeSpecialCharacters,
  calculateRoundedHoursDifference,
} from '../../../utils/utils';

type ItemRowProps = {
  remainingValueMovimented: number;
  setRemainingValueMovimented: (value: number) => void;
  form: FormInstance;
  itemIndex: number;
  totalMovimented: number;
  selectedFare: FareType;
  totalValue: number;
  totalAmount: number;
};

function ItemApportionment(props: {
  index: number;
  onRemove?: (index: number | number[]) => void;
  itemRowProps?: ItemRowProps;
}) {
  const { index, onRemove, itemRowProps } = props;
  const {
    remainingValueMovimented,
    setRemainingValueMovimented,
    form,
    itemIndex,
    totalMovimented,
    selectedFare,
    totalValue,
    totalAmount,
  } = itemRowProps || ({} as ItemRowProps);

  const [proratedAmount, setProratedAmount] = useState(
    form.getFieldValue([
      'tables',
      0,
      'items',
      itemIndex,
      'childs',
      index,
      'prorated_amount',
    ])
  );

  const isDisableEdit =
    form.getFieldValue([
      'tables',
      0,
      'items',
      itemIndex,
      'childs',
      index,
      'id',
    ]) !== undefined;

  const proratedPercent = useMemo(() => {
    return (totalValue / totalAmount) * proratedAmount || 0;
  }, [totalValue, totalAmount, proratedAmount]);

  const [searchCompanyName, setSearchCompanyName] = useState('');

  const { data: companiesData, isLoading: isLoadingCompaniesData } =
    useGetCompaniesQuery({
      page_size: 100,
      name_or_cnpj: searchCompanyName,
    });

  function handleChangeProratedAmount(value: number) {
    setProratedAmount(value);
    const totalRowsValue =
      form
        .getFieldValue(['tables', 0, 'items', itemIndex, 'childs'])
        ?.reduce(
          (sum: number, current: { prorated_amount: number }) =>
            sum + (current.prorated_amount || 0),
          0
        ) || 0;

    setRemainingValueMovimented(totalMovimented - totalRowsValue);
  }

  function onSearchCompanies(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchCompanyName(search || evt);
  }

  return (
    <Row
      gutter={16}
      style={{
        background: '#fff',
        margin: '8px 16px 8px 16px',
        padding: '5px',
      }}
    >
      {isDisableEdit ? (
        <Col span={11}>
          <strong>
            {form.getFieldValue([
              'tables',
              0,
              'items',
              itemIndex,
              'childs',
              index,
              'fare_company_name',
            ])}
          </strong>
        </Col>
      ) : (
        <FormItemSelect
          colSpan={11}
          name={[index, 'fare_company_id']}
          dataList={companiesData?.results
            .filter((company) => company.id !== selectedFare.company?.id)
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          isLoading={isLoadingCompaniesData}
          onSearch={debounce(onSearchCompanies, 300)}
          required
          disabled={isDisableEdit}
        />
      )}

      <FormItemInputNumber
        colSpan={6}
        name={[index, 'prorated_amount']}
        addonAfter="ton"
        style={{ marginBottom: '0px' }}
        required
        onChange={(e) => handleChangeProratedAmount(Number(e))}
        min={1}
        max={
          remainingValueMovimented +
          form.getFieldValue([
            'tables',
            0,
            'items',
            itemIndex,
            'childs',
            index,
            'prorated_amount',
          ])
        }
        disabled={isDisableEdit}
      />
      <Col span={5} style={{ paddingTop: '7px' }}>
        <span style={{ fontFamily: 'Consolas' }}>
          {formatNumberToLocale(proratedPercent, true)}
        </span>
      </Col>
      <Col span={2}>
        <Button
          type="default"
          danger
          icon={<DeleteOutlined />}
          onClick={() => onRemove && onRemove(index)}
        />
      </Col>
    </Row>
  );
}

type TableItemCollapseProps = {
  form: FormInstance;
  itemIndex: number;
  tableIndex: number;
  selectedTableType: string;
  selectedFare?: FareType;
  selectedCharge?: ChargeType;
  isAddCharge?: boolean;
  isComplementary?: boolean;
  selectedTableItems?: PriceTableItemType[];
};

const tableFormListColumns = [{ title: 'Rateios de item', colSpan: 24 }];

export function TableItemCollapse(props: TableItemCollapseProps) {
  const {
    form,
    itemIndex,
    tableIndex,
    selectedTableType,
    selectedFare,
    selectedCharge,
    isAddCharge,
    isComplementary,
    selectedTableItems,
  } = props;

  const [totalMovimented, setTotalMovimented] = useState(0);

  const [searchCargoTypesData, setSearchCargoTypesData] = useState('');

  const [remainingValueMovimented, setRemainingValueMovimented] = useState(0);

  const [totalValue, setTotalValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [isCargoTypeIsVehicle, setCargoTypeIsVehicle] = useState(false);

  const [utilizationUnit, setUtilizationUnit] = useState('');

  const daysCheck = useMemo(() => {
    return isCargoTypeIsVehicle ? 15 : 10;
  }, [isCargoTypeIsVehicle]);

  function handleChangeDwt(dwt: number) {
    handleChangeAmount(dwt);
    handleTableItemValue();
  }

  function handleChangeLOA() {
    handleTableItemValue();
  }
  function handleChangeMovimentedCargo(movimentedCargo: number) {
    handleChangeAmount(movimentedCargo);
    handleTableItemValue();
  }
  function handleChangeQuantity() {
    handleTableItemValue();
  }
  function handleChangeWeighbridgeUsage() {
    handleTableItemValue();
  }
  function onChangeUtilization() {
    handleTableItemValue();
  }

  function handleTableItemValue() {
    const getFieldValue = (field: string) =>
      form.getFieldValue(['tables', 0, 'items', itemIndex, field]);

    const calculateTotal = (value1: number, value2 = 1) =>
      Number((value1 * value2 * fee).toFixed(2));

    const fee = getFieldValue('fee');
    const dwt = getFieldValue('dwt');
    const loa = getFieldValue('loa');
    const utilization = getFieldValue('utilization');
    const weighbridgeUsage = getFieldValue('weighbridge_usage');
    const movimentedCargo = getFieldValue('movimented_cargo');
    const quantity = getFieldValue('quantity');
    const number_of_daily_rates = getFieldValue('number_of_daily_rates');

    const COURTESY_HOUR = 1;

    if (selectedTableType === 'table_one' && dwt) {
      setTableValue(calculateTotal(dwt));
    } else if (selectedTableType === 'table_two') {
      const firstCable = selectedCharge?.docking?.first_cable_tied_in;
      const lastCable = selectedCharge?.docking?.last_cable_untied_in;

      if (firstCable && lastCable && loa) {
        const dockingDuration = calculateRoundedHoursDifference(
          firstCable,
          lastCable
        );
        const totalDockingDuration = dockingDuration - COURTESY_HOUR;
        setTableValue(calculateTotal(totalDockingDuration, loa));
      }
    } else if (
      selectedTableType === 'table_three' ||
      selectedTableType === 'table_nine'
    ) {
      if (movimentedCargo) {
        setTableValue(calculateTotal(movimentedCargo));
      }
    } else if (selectedTableType === 'table_six' && weighbridgeUsage) {
      setTableValue(calculateTotal(weighbridgeUsage));
    } else if (selectedTableType === 'table_seven' && utilization) {
      setTableValue(calculateTotal(utilization));
    } else if (
      selectedTableType === 'table_five' &&
      quantity &&
      number_of_daily_rates
    ) {
      setTableValue(calculateTotal(quantity, number_of_daily_rates));
    }
  }

  function setTableValue(newValue: number) {
    const currentTables = form.getFieldValue('tables') || [];
    currentTables[0].items[itemIndex].value = newValue;
    form.setFieldsValue({ tables: currentTables });
  }
  useEffect(() => {
    let alreadyProratedAmount = 0;
    const childs = form.getFieldValue([
      'tables',
      0,
      'items',
      itemIndex,
      'childs',
    ]);
    if (childs) {
      alreadyProratedAmount = childs.reduce(
        (sum: number, current: { prorated_amount: number }) =>
          sum + (current.prorated_amount || 0),
        0
      );
    }
    setTotalValue(
      form.getFieldValue(['tables', 0, 'items', itemIndex, 'value']) || 0
    );
    let amount = 0;
    if (selectedTableType === 'table_one') {
      amount = form.getFieldValue(['tables', 0, 'items', itemIndex, 'dwt']);
    } else if (selectedTableType === 'table_three') {
      amount = form.getFieldValue([
        'tables',
        0,
        'items',
        itemIndex,
        'movimented_cargo',
      ]);
    }
    if (amount > 0) {
      setTotalMovimented(amount || 0);
      setTotalAmount(amount || 0);
      setRemainingValueMovimented((amount || 0) - alreadyProratedAmount);
    }
  }, [selectedTableType]);

  function handleChangeAmount(e: number) {
    setTotalAmount(e);
    setTotalMovimented(e);
    const childs = form.getFieldValue([
      'tables',
      0,
      'items',
      itemIndex,
      'childs',
    ]);
    let totalRowsValue = 0;
    if (childs) {
      totalRowsValue = childs.reduce(
        (sum: number, current: { prorated_amount: number }) =>
          sum + (current.prorated_amount || 0),
        0
      );
    }
    setRemainingValueMovimented(e - totalRowsValue);
  }

  function handleChangeValue(e: number) {
    setTotalValue(e);
  }

  const { data: navigationTypesData, isLoading: isLoadingNavigationTypesData } =
    useGetSystemParamByNameQuery({
      name: 'NAVIGATION_TYPE',
    });

  const { data: operationTypesData, isLoading: isLoadingOperationTypesData } =
    useGetSystemParamByNameQuery({
      name: 'OPERATION_TYPE',
    });

  const { data: complementTypesData, isLoading: isLoadingComplementTypesData } =
    useGetSystemParamByNameQuery({
      name: 'COMPLEMENT_TYPE',
    });

  const { data: productGroupData, isLoading: isLoadingProductGroups } =
    useGetProductGroupsQuery();

  const { data: dockingPlacesData, isLoading: isLoadingDockingPlacesData } =
    useGetBerthsQuery({ shipType: '' });

  const { data: cargoTypesData, isLoading: isLoadingCargoTypesData } =
    useGetCargoTypeQuery({
      name: searchCargoTypesData,
    });

  const [periods, setPeriods] = useState(
    {} as {
      firstPeriod: string;
      firstMoment: Moment;
      secondPeriod: string;
      secondMoment: Moment;
    }
  );

  useEffect(() => {
    if (
      selectedFare?.table_five &&
      selectedFare?.table_five.items &&
      selectedFare?.table_five.items.length > 0
    ) {
      const hasCargoArrivalDate =
        selectedFare.table_five &&
        selectedFare.table_five.items &&
        selectedFare.table_five.items[itemIndex] &&
        selectedFare.table_five.items[itemIndex].cargo_arrival_date &&
        selectedFare.table_five.items[itemIndex].cargo_arrival_date !==
          undefined;
      const hasCargoDepartureDate =
        selectedFare.table_five &&
        selectedFare.table_five.items &&
        selectedFare.table_five.items[itemIndex] &&
        selectedFare.table_five.items[itemIndex].cargo_departure_date &&
        selectedFare.table_five.items[itemIndex].cargo_departure_date !==
          undefined;
      const firstMoment = selectedFare.table_five.items[itemIndex]
        ?.cargo_arrival_date as Moment;
      const secondMoment = selectedFare.table_five.items[itemIndex]
        ?.cargo_departure_date as Moment;
      if (hasCargoArrivalDate && hasCargoDepartureDate) {
        let tenDaysAfterFirstMoment = null;
        if (
          firstMoment &&
          secondMoment &&
          secondMoment.diff(firstMoment, 'days') >= daysCheck
        ) {
          const first = _.cloneDeep(firstMoment);
          tenDaysAfterFirstMoment = `${createDateStringPtBr(
            first.add(daysCheck, 'days')
          )}`;
        }
        setPeriods({
          firstMoment,
          secondMoment,
          firstPeriod: `${createDateStringPtBr(firstMoment)} - ${
            tenDaysAfterFirstMoment || createDateStringPtBr(secondMoment)
          }`,
          secondPeriod: tenDaysAfterFirstMoment
            ? `${tenDaysAfterFirstMoment} - ${createDateStringPtBr(
                secondMoment
              )}`
            : '',
        });
      } else if (hasCargoArrivalDate) {
        setPeriods({
          firstMoment,
          secondMoment: {} as Moment,
          firstPeriod: createDateStringPtBr(firstMoment) || '',
          secondPeriod: '',
        });
      }
    }
  }, [selectedFare, isCargoTypeIsVehicle]);

  useEffect(() => {
    if (
      selectedFare?.table_five &&
      selectedFare?.table_five.items &&
      selectedFare?.table_five.items.length > 0
    )
      setCargoTypeIsVehicle(
        selectedFare?.table_five?.items[itemIndex]?.cargo_type?.name ===
          'VEÍCULOS'
      );
  }, [selectedFare]);

  function handleChangeCargoArrivalDate(date: Moment) {
    let tenDaysAfterFirstMoment = '';
    let secondPeriod = '';
    const selectedDate = _.cloneDeep(date);
    if (
      periods.secondMoment &&
      !isEmpty(periods.secondMoment) &&
      periods.secondMoment.diff(date, 'days') >= daysCheck
    ) {
      tenDaysAfterFirstMoment = `${createDateStringPtBr(
        selectedDate.add(daysCheck, 'days')
      )}`;
      secondPeriod = `${tenDaysAfterFirstMoment} - ${createDateStringPtBr(
        periods.secondMoment
      )}`;
    } else {
      tenDaysAfterFirstMoment =
        periods.secondMoment && !isEmpty(periods.secondMoment)
          ? createDateStringPtBr(periods.secondMoment) || ''
          : '';
    }
    setPeriods((prev) => ({
      ...prev,
      firstPeriod: `${createDateStringPtBr(date)} - ${tenDaysAfterFirstMoment}`,
      firstMoment: date,
      secondPeriod,
    }));
  }

  function handleChangeCargoDepartureDate(date: Moment) {
    if (periods.firstMoment > date) {
      message.error(
        'Fim do armazenamento deve ser maior que Início de armazenamento'
      );
      return;
    }
    let firstPeriod = _.cloneDeep(periods.firstPeriod);
    const firstMoment = _.cloneDeep(periods.firstMoment);
    let tenDaysAfterFirstMoment = '';
    let secondPeriod = '';
    firstPeriod = `${createDateStringPtBr(
      periods.firstMoment
    )} - ${createDateStringPtBr(date)}`;
    if (date.diff(periods.firstMoment, 'days') >= daysCheck) {
      tenDaysAfterFirstMoment =
        createDateStringPtBr(firstMoment.add(daysCheck, 'days')) || '';
      firstPeriod = `${createDateStringPtBr(
        periods.firstMoment
      )} - ${tenDaysAfterFirstMoment}`;
      secondPeriod = `${tenDaysAfterFirstMoment} - ${
        createDateStringPtBr(date) || ''
      }`;
    }
    setPeriods((prev) => ({
      ...prev,
      firstPeriod,
      secondPeriod,
      secondMoment: date,
    }));
  }

  function handleSelectCargoType(cargoName: string) {
    setCargoTypeIsVehicle(cargoName === 'VEÍCULOS');
  }

  function itemOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.number} value={option.number}>
        {`${option.number} - ${option.name}`}
      </Select.Option>
    );
  }
  function setUtilizationUnitTableSeven(
    selectedItem: PriceTableItemType | undefined
  ) {
    const itemName = selectedItem?.name?.toLocaleLowerCase();
    if (itemName) {
      if (itemName.includes('m²')) {
        setUtilizationUnit('m²');
      } else if (itemName.includes('m³')) {
        setUtilizationUnit('m³');
      } else if (itemName.includes('kwh')) {
        setUtilizationUnit('kwh');
      }
    }
  }
  function onSelectItem(e: any) {
    const selectedItem = selectedTableItems?.find((item) => item.number === e);
    const currentFormTables = form.getFieldValue('tables');
    const currentTableItems = currentFormTables[tableIndex].items;
    currentTableItems[itemIndex] = {
      ...currentTableItems[itemIndex],
      code: selectedItem?.code,
      fee: selectedItem?.price,
      name: selectedItem?.name,
      number: selectedItem?.number,
    };
    currentFormTables[tableIndex] = {
      ...currentFormTables[tableIndex],
      items: currentTableItems,
    };
    form.setFieldsValue({
      tables: currentFormTables,
    });
    setUtilizationUnitTableSeven(selectedItem);
    handleTableItemValue();
  }

  function onSearchCargoTypesData(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchCargoTypesData(search || evt);
  }

  return (
    <div style={{ padding: '16px' }}>
      <Row gutter={16}>
        <FormItemSelect
          name={[itemIndex, 'number']}
          label="Nome"
          required
          showSearch
          dataList={selectedTableItems}
          optionRenderer={itemOptionRenderer}
          onSelect={onSelectItem}
        />
        <FormItem noStyle name={[itemIndex, 'code']} />
        <FormItem noStyle name={[itemIndex, 'name']} />
        <FormItem noStyle name={[itemIndex, 'fee']} />
      </Row>
      <Row gutter={16}>
        {selectedTableType === 'table_one' && (
          <FormItemSelect
            colSpan={12}
            name={[itemIndex, 'product_group']}
            dataList={productGroupData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingProductGroups}
            label="Grupo de produto"
            mode="multiple"
            maxSelectOptions={5}
          />
        )}
        {selectedTableType === 'table_two' && (
          <FormItemSelect
            colSpan={12}
            label="Local de atracação"
            name={[itemIndex, 'docking_place', 'tag']}
            dataList={dockingPlacesData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingDockingPlacesData}
          />
        )}
        {['table_three', 'table_five'].includes(selectedTableType) && (
          <FormItemSelect
            label="Tipo de mercadoria"
            name={[itemIndex, 'cargo_type', 'name']}
            colSpan={12}
            showSearch
            isLoading={isLoadingCargoTypesData}
            onSearch={debounce(onSearchCargoTypesData, 100)}
            dataList={cargoTypesData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            onSelect={handleSelectCargoType}
          />
        )}
        {selectedTableType === 'table_five' && (
          <>
            <FormItemInputNumber
              name={[itemIndex, 'quantity']}
              colSpan={12}
              addonAfter="un"
              label="Quantidade"
              defaultValue={1}
              min={0}
              maxLength={9}
              onChange={() => handleChangeQuantity()}
              rules={[{ required: true, message: 'Este campo é obrigatório!' }]}
            />
            {/* <FormItemDatePicker
              colSpan={12}
              label="Início de armazenamento"
              name={[itemIndex, 'cargo_arrival_date']}
              minuteStep={15}
              onChange={handleChangeCargoArrivalDate}
            />
            <FormItemDatePicker
              colSpan={12}
              label="Fim do armazenamento"
              name={[itemIndex, 'cargo_departure_date']}
              minuteStep={15}
              onChange={handleChangeCargoDepartureDate}
            /> */}
            <FormItemInputNumber
              colSpan={12}
              label="Número de diárias"
              name={[itemIndex, 'number_of_daily_rates']}
              maxLength={9}
              min={0}
              onChange={() => handleChangeQuantity()}
              rules={[{ required: true, message: 'Este campo é obrigatório!' }]}
            />
            <FormItemInput
              colSpan={12}
              label={`Primeiro período (até ${daysCheck} dias)`}
              value={periods.firstPeriod}
              readOnly
            />
            <FormItemInput
              colSpan={12}
              label={`Segundo período (após ${daysCheck} dias)`}
              value={periods.secondPeriod}
              readOnly
            />
          </>
        )}
        {selectedTableType === 'table_six' && (
          <FormItemInputNumber
            colSpan={12}
            label="Ultilização da balança rodoviária"
            name={[itemIndex, 'weighbridge_usage']}
            maxLength={9}
            min={0}
            addonAfter="h"
            onChange={() => handleChangeWeighbridgeUsage()}
          />
        )}
        {selectedTableType === 'table_seven' && (
          <FormItemInputNumber
            colSpan={12}
            label="Entrega ou Utilização"
            name={[itemIndex, 'utilization']}
            maxLength={9}
            addonAfter={utilizationUnit}
            onChange={() => onChangeUtilization()}
          />
        )}
        {selectedTableType === 'table_nine' && (
          <FormItemSelect
            colSpan={12}
            name={[itemIndex, 'complement_type', 'id']}
            label="Tipo de complemento"
            allowClear
            dataList={complementTypesData?.items
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingComplementTypesData}
          />
        )}
        {['table_three', 'table_nine'].includes(selectedTableType) && (
          <FormItemInputNumber
            name={[itemIndex, 'movimented_cargo']}
            colSpan={12}
            addonAfter="ton"
            label={
              selectedTableType === 'table_three'
                ? 'Carga movimentada'
                : 'Total movimentado'
            }
            maxLength={9}
            onChange={(e) => handleChangeMovimentedCargo(Number(e))}
          />
        )}
        <FormItemInputNumber
          colSpan={12}
          name={[itemIndex, 'value']}
          addonBefore="R$"
          label="Valor do item"
          readOnly={selectedTableType === 'table_five'}
          maxLength={14}
          onChange={(e) => handleChangeValue(Number(e))}
          formatter={(value) =>
            `${value}`
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              .replace(/\.(?=\d{0,2}$)/g, ',')
          }
        />
        {['table_one', 'table_two'].includes(selectedTableType) && (
          <FormItemSelect
            colSpan={12}
            name={[itemIndex, 'navigation_type', 'id']}
            label="Tipo de navegação"
            allowClear
            dataList={navigationTypesData?.items
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingNavigationTypesData}
          />
        )}
        {selectedTableType === 'table_one' && (
          <>
            <FormItemInputNumber
              colSpan={12}
              name={[itemIndex, 'dwt']}
              label="DWT"
              addonAfter="ton"
              maxLength={12}
              onChange={(e) => handleChangeDwt(Number(e))}
            />
            <FormItemSelect
              colSpan={12}
              name={[itemIndex, 'operation_type', 'id']}
              label="Tipo de operação"
              allowClear
              dataList={operationTypesData?.items
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              isLoading={isLoadingOperationTypesData}
            />
          </>
        )}
        {/* <FormItemInputNumber
          name={[itemIndex, 'weight']}
          colSpan={12}
          addonAfter="ton"
          label="Peso"
          maxLength={9}
        /> */}
        {selectedTableType === 'table_two' && (
          <>
            <FormItemInputNumber
              colSpan={12}
              name={[itemIndex, 'loa']}
              label="LOA"
              addonAfter="m"
              maxLength={12}
              onChange={() => handleChangeLOA()}
            />
            <FormItemInputNumber
              colSpan={12}
              name={[itemIndex, 'fraction_of_minutes_after_limit']}
              addonAfter="min"
              label="Fração de minutos após o limite"
              maxLength={9}
            />
            <FormItemInputNumber
              colSpan={12}
              name={[itemIndex, 'fraction_of_minutes_before_limit']}
              addonAfter="min"
              label="Fração de minutos antes do limite"
              maxLength={9}
            />
          </>
        )}
        <FormItemsCheckbox
          colSpan={12}
          label="Faturamento separado"
          name={[String(itemIndex), 'segregated_billing']}
        />
      </Row>
      <Row>
        <FormItemTextArea
          label="Observações"
          name={[itemIndex, 'observations']}
          maxLength={500}
          placeholder="Descreva informações adicionais sobre a aplicação da tarifa"
        />
      </Row>
      {!isAddCharge &&
        !isComplementary &&
        ['table_one', 'table_three'].includes(selectedTableType) && (
          <>
            <Divider />
            <div>
              <TableFormList
                title="RATEIOS"
                formListName={[itemIndex, 'childs']}
                FormListItemRow={ItemApportionment}
                columns={tableFormListColumns}
                style={{ marginTop: '0px', marginBottom: '20px' }}
                itemToAdd={{
                  fare_company_id: null,
                  prorated_amount: null,
                }}
                addButtonText="Adicionar responsável"
                disabledAddButton={remainingValueMovimented === 0}
                addButtonStyle={{ left: '58%', marginBottom: '20px' }}
                itemRowProps={{
                  remainingValueMovimented,
                  setRemainingValueMovimented,
                  form,
                  itemIndex,
                  totalMovimented,
                  selectedFare,
                  totalValue,
                  totalAmount,
                }}
              />
              {totalMovimented > 0 && (
                <Alert
                  type={
                    remainingValueMovimented === 0
                      ? 'success'
                      : remainingValueMovimented < 0
                      ? 'error'
                      : 'warning'
                  }
                  message={
                    remainingValueMovimented === 0
                      ? `Valor total atribuído`
                      : `Para efetuar o rateio do valor do item, a quantidade deve estar distribuída entre os responsáveis financeiros. 
Restam distribuir ${remainingValueMovimented} ton`
                  }
                />
              )}
            </div>
          </>
        )}
    </div>
  );
}
