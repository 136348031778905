import { Row, FormInstance } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';

import {
  FormItemDatePicker,
  FormItemInput,
  FormItemInputNumber,
  FormItemSelect,
} from '../../../components';
import { FormItem } from '../../../components/antd/Form';
import { FormItemDUV } from '../../../components/antd/formItems/FormItemDUV';
import { Select } from '../../../components/antd/Select';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import {
  useGetPortsQuery,
  useGetPortsCountriesQuery,
} from '../../../services/vesselApi';
import { Stopover } from '../../../types';
import { booleanList } from '../../../utils/lists';
import {
  enableDatesBetween,
  disabledDateTimeBeforeNow,
  removeSpecialCharacters,
  maskString,
  isNullOrUndefined,
  disableDateTimeBeforeAndAfterDay,
  addZeroToDUV,
} from '../../../utils/utils';

type FormItemsStopoverProps = {
  form: FormInstance;
  readOnly?: boolean;
  selectedStopover?: Stopover;
  smallForm?: boolean;
  stopoverRoot?: boolean;
  style?: CSSProperties;
};

export function FormItemsStopover(props: FormItemsStopoverProps) {
  const { form, readOnly, selectedStopover, smallForm, stopoverRoot, style } =
    props;
  const [selectedLastPort, setSelectedLastPort] = useState(Object);
  const [selectedLastPortCountry, setSelectedLastPortCountry] =
    useState<string>();
  const [searchLastPortCountry, setSearchLastPortCountry] = useState({});
  const [skipSearchLastPortCountry, setSkipSearchLastPortCountry] =
    useState(true);
  const [searchLastPort, setSearchLastPort] = useState({});
  const [skipSearchLastPort, setSkipSearchLastPort] = useState(true);

  const [selectedNextPort, setSelectedNextPort] = useState(Object);
  const [selectedNextPortCountry, setSelectedNextPortCountry] =
    useState<string>();
  const [searchNextPortCountry, setSearchNextPortCountry] = useState({});
  const [skipSearchNextPortCountry, setSkipSearchNextPortCountry] =
    useState(true);
  const [searchNextPort, setSearchNextPort] = useState({});
  const [skipSearchNextPort, setSkipSearchNextPort] = useState(true);

  const [searchShipownerName, setSearchShipownerName] = useState(
    form.getFieldValue(['charterer', 'name'])
  );
  const [searchShippingAgencyName, setSearchShippingAgencyName] = useState(
    form.getFieldValue(['shipping_agency', 'name'])
  );
  const [searchProtectiveAgencyName, setSearchProtectiveAgencyName] = useState(
    form.getFieldValue(['protective_agency', 'name'])
  );

  const { data: lastPortsData, isLoading: isLoadingLastPortData } =
    useGetPortsQuery(searchLastPort, {
      skip: skipSearchLastPort,
    });

  const {
    data: countriesDataLastPort,
    isLoading: isLoadingCountriesDataLastPort,
  } = useGetPortsCountriesQuery(searchLastPortCountry, {
    skip: skipSearchLastPortCountry,
  });

  const { data: nextPortsData, isLoading: isLoadingNextPortData } =
    useGetPortsQuery(searchNextPort, {
      skip: skipSearchNextPort,
    });

  const {
    data: countriesDataNextPort,
    isLoading: isLoadingCountriesDataNextPort,
  } = useGetPortsCountriesQuery(searchNextPortCountry, {
    skip: skipSearchNextPortCountry,
  });

  const { data: protectiveAgencyData } = useGetCompaniesQuery(
    {
      name_or_cnpj: searchProtectiveAgencyName,
      company_type: 'PROTECTIVE_AGENCY',
    },
    { skip: searchProtectiveAgencyName === '' }
  );

  const { data: shippingAgencyData } = useGetCompaniesQuery(
    { name_or_cnpj: searchShippingAgencyName, company_type: 'SHIPPING_AGENCY' },
    { skip: searchShippingAgencyName === '' }
  );

  const { data: shipownerData } = useGetCompaniesQuery(
    { name_or_cnpj: searchShipownerName, company_type: 'SHIPOWNER' },
    { skip: searchShipownerName === '' }
  );

  const { data: navigationTypesData } = useGetSystemParamByNameQuery({
    name: 'NAVIGATION_TYPE',
  });

  function onSelectLastPort(option: string) {
    const portObject = lastPortsData?.results.find((port) => {
      return port.id === option;
    });
    setSelectedLastPort({ ...portObject });
  }

  function onSelectNextPort(option: string) {
    const portObject = nextPortsData?.results.find((port) => {
      return port.id === option;
    });
    setSelectedNextPort({ ...portObject });
  }

  useEffect(() => {
    if (!readOnly) {
      const last_port_country_code = form.getFieldValue([
        'last_port',
        'country_code',
      ]);
      const next_port_country_code = form.getFieldValue([
        'next_port',
        'country_code',
      ]);

      if (last_port_country_code) {
        setSelectedLastPortCountry(last_port_country_code);
        setSearchLastPort({ countryCode: last_port_country_code });
        setSkipSearchLastPort(false);
      }
      if (next_port_country_code) {
        setSelectedNextPortCountry(next_port_country_code);
        setSearchNextPort({ countryCode: next_port_country_code });
        setSkipSearchNextPort(false);
      }
    }
  }, [readOnly]);

  useEffect(() => {
    form.setFieldsValue({
      last_port: {
        ...selectedLastPort,
      },
      next_port: {
        ...selectedNextPort,
      },
    });
  }, [selectedLastPort, selectedNextPort]);

  useEffect(() => {
    const lastPortObject = lastPortsData?.results.find((port) => {
      return port.id === selectedStopover?.last_port?.bi_trigram;
    });
    setSelectedLastPort({ ...lastPortObject });

    const nextPortObject = nextPortsData?.results.find((port) => {
      return port.id === selectedStopover?.next_port?.bi_trigram;
    });
    setSelectedNextPort({ ...nextPortObject });
  }, [selectedStopover, lastPortsData, nextPortsData]);

  function onSeachProtectiveAgency(val: string) {
    setSearchProtectiveAgencyName(val);
  }

  function onSeachShippingAgency(val: string) {
    setSearchShippingAgencyName(val);
  }

  function onSearchShipowner(val: string) {
    setSearchShipownerName(val);
  }

  function onSearchCountryLastPortByName(val: string) {
    setSearchLastPortCountry({ countryName: val });
    setSkipSearchLastPortCountry(false);
  }

  function onSearchCountryNextPortByName(val: string) {
    setSearchNextPortCountry({ countryName: val });
    setSkipSearchNextPortCountry(false);
  }

  function onSearchLastPortByName(val: string) {
    setSearchLastPort({ name: val, countryCode: selectedLastPortCountry });
    setSkipSearchLastPort(false);
  }

  function onSearchNextPortByName(val: string) {
    setSearchNextPort({ name: val, countryCode: selectedNextPortCountry });
    setSkipSearchNextPort(false);
  }

  function onSelectLastPortCountry(val: any) {
    setSelectedLastPortCountry(val);
    setSearchLastPort({ countryCode: val });
    setSkipSearchLastPort(false);
    setSelectedLastPort({
      country_code: val,
      name: null,
      bi_trigram: null,
    });
  }

  function onSelectNextPortCountry(val: any) {
    setSelectedNextPortCountry(val);
    setSearchNextPort({ countryCode: val });
    setSkipSearchNextPort(false);
    setSelectedNextPort({
      country_code: val,
      name: null,
      bi_trigram: null,
    });
  }

  function onClearSelectedLastPortCountry() {
    setSelectedLastPortCountry('');
    setSelectedLastPort({});
  }

  function onClearSelectedNextPortCountry() {
    setSelectedNextPortCountry('');
    setSelectedNextPort({});
  }

  function onClearSelectedLastPort() {
    setSelectedLastPort({});
  }

  function onClearSelectedNextPort() {
    setSelectedNextPort({});
  }

  function createName(path: Array<string>) {
    if (stopoverRoot) {
      return ['stopover'].concat(path);
    }
    return path;
  }

  function shipownerOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.id} value={option.id}>
        {`${option.identifier} - ${option.name}`}
      </Select.Option>
    );
  }

  function getMaxDraught() {
    const firstDockingPlace = form.getFieldValue([
      'dockings',
      0,
      'docking_place',
    ]);
    return firstDockingPlace?.max_draught || 0;
  }
  const dockings_valid = form
    .getFieldValue('dockings')
    ?.filter((docking: any) => docking.status !== 'CANCELED');
  console.log(dockings_valid);
  return (
    <div style={style}>
      <FormItem name={createName(['code'])} noStyle />
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={16}
          name={createName(['shipping_agency', 'id'])}
          label="Agência de navegação"
          dataList={shippingAgencyData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          allowClear
          disabled={readOnly}
          onSearch={debounce(onSeachShippingAgency, 400)}
          notFoundContent="Digite uma busca"
        />
        <FormItemInput
          colSpan={8}
          name={createName(['mercante_scale'])}
          label="Nº escala mercante"
          readOnly={readOnly}
          className="hide-controls"
          maxLength={15}
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={16}
          name={createName(['charterer', 'id'])}
          label="Armador afretador"
          dataList={shipownerData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          allowClear
          disabled={readOnly}
          onSearch={debounce(onSearchShipowner, 400)}
          notFoundContent="Digite uma busca"
          optionRenderer={shipownerOptionRenderer}
        />
        <FormItemInput
          colSpan={8}
          name={createName(['shipowner_trip'])}
          label="Nº viagem do armador"
          readOnly={readOnly}
          maxLength={20}
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={16}
          name={createName(['protective_agency', 'id'])}
          label="Agência protetora"
          dataList={protectiveAgencyData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          allowClear
          disabled={readOnly}
          onSearch={debounce(onSeachProtectiveAgency, 400)}
          notFoundContent="Digite uma busca"
        />
        <FormItemInputNumber
          colSpan={8}
          name="arrival_draught"
          label="Calado de chegada"
          addonAfter="m"
          maxLength={8}
          disabled={readOnly}
          rules={
            getMaxDraught() > 0
              ? [
                  {
                    type: 'number',
                    max: getMaxDraught(),
                    message: `Valor deve ser no máximo ${getMaxDraught()}`,
                  },
                ]
              : []
          }
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={12}
          disabled={readOnly}
          name={createName(['last_port', 'country_name'])}
          label="País do porto de origem"
          allowClear
          showSearch
          onSearch={debounce(onSearchCountryLastPortByName, 400)}
          onSelect={onSelectLastPortCountry}
          onClear={onClearSelectedLastPortCountry}
          dataList={countriesDataLastPort?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingCountriesDataLastPort}
          notFoundContent="Nenhum país encontrado para o filtro informado"
        />
        <FormItem name={createName(['last_port', 'bi_trigram'])} noStyle />
        <FormItemSelect
          colSpan={12}
          disabled={readOnly}
          name={createName(['last_port', 'name'])}
          label="Porto de origem"
          allowClear
          showSearch
          onSearch={debounce((e) => onSearchLastPortByName(e), 400)}
          onSelect={onSelectLastPort}
          onClear={onClearSelectedLastPort}
          dataList={lastPortsData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingLastPortData}
          notFoundContent="Nenhum porto encontrado para o filtro informado"
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={8}
          disabled={readOnly}
          name={createName(['next_port', 'country_name'])}
          label="País do porto de destino"
          allowClear
          showSearch
          onSearch={debounce(onSearchCountryNextPortByName, 400)}
          onSelect={onSelectNextPortCountry}
          onClear={onClearSelectedNextPortCountry}
          dataList={countriesDataNextPort?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingCountriesDataNextPort}
          notFoundContent="Nenhum país encontrado para o filtro informado"
        />
        <FormItem name={createName(['next_port', 'bi_trigram'])} noStyle />
        <FormItemSelect
          colSpan={8}
          disabled={readOnly}
          name={createName(['next_port', 'name'])}
          label="Porto de destino"
          allowClear
          showSearch
          onSearch={debounce((e) => onSearchNextPortByName(e), 400)}
          onSelect={onSelectNextPort}
          onClear={onClearSelectedNextPort}
          dataList={nextPortsData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingNextPortData}
          notFoundContent="Nenhum porto encontrado para o filtro informado"
        />
        <FormItemSelect
          colSpan={8}
          name={createName(['navigation_type', 'id'])}
          label="Tipo de navegação"
          dataList={navigationTypesData?.items}
          disabled={readOnly}
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemDatePicker
          colSpan={16}
          label="Previsão de chegada ETA (agente de navegação)"
          name={createName(['expected_arrival'])}
          disabled={readOnly}
          required
          disabledDate={(e) =>
            dockings_valid?.length > 0
              ? enableDatesBetween(
                  e,
                  moment().subtract(24, 'hour').startOf('day'),
                  dockings_valid[0].pilot_expected_on_board ||
                    dockings_valid[0].pilot_expected_leaving_on_board ||
                    dockings_valid[0].expected_berthing ||
                    dockings_valid[0].expected_unberthing
                )
              : enableDatesBetween(
                  e,
                  moment().subtract(24, 'hour').startOf('day'),
                  null
                )
          }
          disabledTime={(e) =>
            disableDateTimeBeforeAndAfterDay(
              e,
              moment().subtract(24, 'hour'),
              null
            )
          }
          minuteStep={15}
        />
        <FormItemDUV
          colSpan={8}
          name={createName(['duv'])}
          readOnly={readOnly}
          onBlur={(e) => addZeroToDUV(e.target.value, form)}
        />
      </Row>
    </div>
  );
}
