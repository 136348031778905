import { FormInstance } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { isBoolean, isNull, isUndefined } from 'lodash';
import moment, { isMoment, Moment, unitOfTime } from 'moment';

import { ErrorType, User as UserType } from '../types';
import { logItemsNames, staymentLogNames } from './lists';

// anchoring window row height
export const berthRowHeight = 45;
export const itemRowHeight = berthRowHeight - 7.5;
export const OPERATOR_GROUP_ID = 4;
export const VEHICLES_WAREHOUSING_OPERATOR_GROUP_ID = 99;
export const STORAGE_TERMINAL_GROUP_ID = 5;
export const AGENT_GROUP_ID = 7;
export const ADMIN_ID = 1;

function range(start: number, end: number) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function createDate(
  str: string | null | Moment,
  format?: string | null
) {
  if (!isNullOrUndefined(str)) {
    return format ? moment(str, format) : moment(str);
  }
  return null;
}

export function createDateString(
  str: string | null | undefined | Moment,
  isJustDate = false
) {
  if (!isNullOrUndefined(str)) {
    if (isJustDate) {
      return moment(str).format('YYYY-MM-DD');
    }
    return moment(str).format('YYYY-MM-DD HH:mm');
  }
  return null;
}

export function createTimeString(str: string | null | undefined | Moment) {
  if (!isNullOrUndefined(str)) {
    return moment(str).format('HH:mm');
  }
  return null;
}

export function mergeDateAndTime(date: Moment, time: Moment) {
  if (isMoment(date) && isMoment(time)) {
    const dateObj = date;
    const timeObj = time;

    dateObj.set({
      hour: timeObj.hour(),
      minute: timeObj.minutes(),
      second: timeObj.seconds(),
    });

    return `${dateObj.year()}-${
      dateObj.month() + 1
    }-${dateObj.date()} ${dateObj.hour()}:${dateObj.minute()}`;
  }
  return null;
}

export function splitDateAndTime(date: string, prop: string) {
  return {
    [`${prop}_date`]: date ? moment(date) : null,
    [`${prop}_time`]: date ? moment(date) : null,
  };
}

export function translateStopoverStatus(status: string) {
  switch (status) {
    case 'WAITING':
      return 'escala aguardada';
    case 'INPROGRESS':
      return 'escala em andamento';
    case 'DONE':
      return 'escala finalizada';
    case 'CANCELED':
      return 'escala cancelada';
    default:
      return '';
  }
}

export function disabledDateBeforeToday(current: any) {
  return current < moment().startOf('day');
}

export function disabledDateAfterToday(current: any) {
  return current > moment().add(5, 'minutes');
}

export function disabledDateTimeBeforeNow(current: any) {
  if (current <= moment()) {
    return {
      disabledHours: () => range(0, 24).splice(0, moment().hour()),
      disabledMinutes: () => range(0, moment().minute()),
    };
  }
  return {};
}

export function disableDateTimeBeforeAndAfterDay(
  current: any,
  initial: Moment | null,
  final: Moment | null
) {
  if (!current) {
    return {};
  }

  let disabledTime = {};

  if (final && current.dayOfYear() === final.dayOfYear()) {
    return {
      disabledHours: () => range(0, 24).splice(final.hour(), 24),
      disabledMinutes: () => range(0, 60).splice(final.minutes() - 15, 60),
    };
  }

  if (
    initial &&
    current.dayOfYear() === initial.dayOfYear() &&
    current.hour() >= initial.hour()
  ) {
    disabledTime = {
      ...disabledTime,
      disabledHours: () => range(0, 24).splice(0, initial.hour()),
    };
  }

  if (
    initial &&
    current.dayOfYear() === initial.dayOfYear() &&
    current.hour() <= initial.hour()
  ) {
    disabledTime = {
      ...disabledTime,
      disabledMinutes: () => range(0, 60).splice(0, initial.minutes() + 15),
    };
  }

  return disabledTime;
}

export function enableDatesBetween(current: any, initial: any, final: any) {
  if (initial && current <= initial.clone().startOf('day')) {
    return true;
  }
  if (final) {
    return moment(current).add(15, 'minutes') >= final;
  }
  return false;
}

export function getGreatestBetweenDates(values: any[]) {
  const larger = values.sort();
  const index = larger.indexOf(undefined);
  if (index > -1) {
    larger.splice(index, 1);
  }
  return larger[larger.length - 1];
}

export function booleanToString(obj: any) {
  const toReturn: any = {};
  Object.keys(obj).forEach((p: string) => {
    if (isBoolean(obj[p])) {
      toReturn[p] = obj[p].toString();
    }
  });
  return toReturn;
}

export function createDateStringPtBr(str: string | null | undefined | Moment) {
  if (!isNullOrUndefined(str)) {
    return moment(str).format('DD/MM/YYYY HH:mm');
  }
  return null;
}

export function createDateStringPtBrSeconds(
  str: string | null | undefined | Moment
) {
  if (!isNullOrUndefined(str)) {
    return moment(str).format('DD/MM/YYYY HH:mm:ss');
  }
  return null;
}

export function returnDaysForToday(str: string | null): string | null {
  if (str !== null && str !== undefined) {
    const date = moment(str).startOf('day');

    if (date.format('x') === moment().startOf('day').format('x')) {
      // ('x') converte para timestamp
      return ' (Chega hoje)';
    }

    const days = date.diff(moment().startOf('day'), 'days');
    if (days === 1) {
      return ` (Falta ${String(days)} dia)`;
    }
    if (days > 1) {
      return ` (Faltam ${String(days)} dias)`;
    }
    if (days < 1) {
      return ' (Atrasado)';
    }
  }
  return null;
}

export function parserNumber(val: any, format = true) {
  if (!val) return '';
  if (val < 0) return 0;
  const retNumber = Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, '').replace(/(,{1})/g, '.')
  );
  return format ? retNumber.toFixed(3) : retNumber;
}

export function formatterNumberOnlyInterger(val: any) {
  if (!val) return ``;
  return `${val}`.replace(/\./gi, '');
}

export function parserNumberOnlyInterger(val: any) {
  return parseInt(val, 10);
}

export function isNullOrUndefined(val: any) {
  return isNull(val) || isUndefined(val) || val === '';
}

export function translatePermission(permission: string) {
  return permission
    .replace('Can add', 'Adicionar')
    .replace('Can change', 'Editar')
    .replace('Can delete', 'Apagar')
    .replace('Can view', 'Visualizar');
}

export function removeSpecialCharacters(
  value: string,
  reg?: RegExp
): string | null {
  if (isNullOrUndefined(value)) {
    return null;
  }
  return value.replace(reg || /[^a-z0-9]/gi, '');
}

export const maskString = (value: string, pattern: string) => {
  if (isNullOrUndefined(value)) {
    return '';
  }
  let i = 0;
  return pattern.replace(/#/g, () => {
    return value[i++];
  });
};

export const maskPhoneNumber = (phoneNumber: string) => {
  if (isNullOrUndefined(phoneNumber)) {
    return '';
  }

  const cellphoneMask = '+ ## (##) # ####-####';
  const phoneMask = '+ ## (##) ####-####';

  if (phoneNumber[2] === '9') {
    return maskString(phoneNumber, cellphoneMask);
  }
  return maskString(phoneNumber, phoneMask);
};

export function generateRandomIntegerInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function addSuffix(
  value: string | number | null,
  suffix: string
): string | null {
  if (isNullOrUndefined(value)) {
    return null;
  }
  return `${value} ${suffix}`;
}

export function formatNumberToLocale(
  value: number | null | undefined,
  withRS = false
): string | null {
  if (value === null || value === undefined) {
    return null;
  }
  const convertedValue = value.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (withRS) {
    return `R$ ${convertedValue}`;
  }
  return convertedValue;
}

export function formatUserName(user: UserType | null | undefined): string {
  if (user === undefined || user === null) return '';
  return user.first_name ? `${user.first_name} ${user.last_name}` : 'Sem nome';
}

export function isErrorType(obj: any): obj is ErrorType {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'data' in obj &&
    'message' in obj.data
  );
}

export function translateManoeuvreStatusName(status: string) {
  if (status === 'SCHEDULED') {
    return 'Prevista';
  }
  if (status === 'IN_PROGRESS') {
    return 'Em andamento';
  }
  if (status === 'CONFIRMED') {
    return 'Confirmada';
  }
  if (status === 'OVERDUE') {
    return 'Atrasada';
  }
}
export function capitalizeFirstLetter(text?: string) {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
  }
  return '';
}

export function convertMinutesToStringHours(minutes: number): string {
  let hoursString = '';
  const totalHours = Math.floor(minutes / 60);
  const totalMinutes = minutes % 60;
  if (totalHours >= 1) {
    hoursString += `${totalHours}h `;
  }
  if (totalMinutes > 0) {
    hoursString += `${totalMinutes}m`;
  }
  return hoursString;
}

export function convertTonToM3(ton: number): string | null {
  const m3 = ton * 2.831684659;
  return m3.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  });
}

export function convertToPercent(
  completed: number | null | undefined,
  total: number | null | undefined
): string | null {
  const totalValue = total !== undefined && total !== null ? total : 1;
  completed = completed || 0;
  const result = (completed / totalValue) * 100;
  return `${result.toFixed(2)} %`;
}

export function convertMinutesToHours(minutes: string | number) {
  return moment.duration(minutes, 'minutes').asHours();
}
export function getDurationBetweenDates(
  start: string | null | undefined,
  end: string | null | undefined,
  unit: unitOfTime.Base
): number {
  if (!start || !end) {
    return moment.duration(0).as(unit);
  }
  return moment.duration(moment(end).diff(moment(start))).as(unit);
}
export function formatHoursMinutes(duration: number) {
  const hours = Math.floor(moment.duration(duration).asHours());
  const minutes = moment.utc(duration).format('mm[m]');
  return `${hours}h ${minutes}`;
}
export function removeRepeatedItemsFromArray(list: any[], checkField: string) {
  return list.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t[checkField] === value[checkField])
  );
}

export function getStopoverStatus(currentDockingStatus: string) {
  return staymentLogNames.find(
    (staymentLog) =>
      staymentLog.id ===
      logItemsNames.find((logItem) => logItem.status === currentDockingStatus)
        ?.statusId
  );
}

export function renderListOfItems(list: string[]) {
  return list.map((item, index) => {
    if (list.length - 1 === index) {
      return item;
    }
    return `${item}, `;
  });
}
export function addZeroToDUV(value: any, form: FormInstance | null = null) {
  let duv = removeSpecialCharacters(value);
  if (isNullOrUndefined(duv)) {
    return;
  }
  duv = duv !== null ? duv.padStart(10, '0') : null;
  duv = duv !== null ? maskString(duv, '######/####') : null;
  if (form) form.setFieldsValue({ duv });
  return duv;
}

export function removeDuplicateFromObjectArray(
  array: Record<string, unknown>[],
  param: string
) {
  if (array) {
    return array.filter(
      (item, index) =>
        array.findIndex((item2) => item[param] === item2[param]) === index
    );
  }
  return [];
}

export function sumDaysToDate(date: string, days: number): string {
  return createDateStringPtBr(moment(date).add(days, 'days')) || '';
}

export function returnDiffDaysInHours(
  start: string,
  end: string
): string | null {
  const miliseconds1 = Date.parse(start);
  const miliseconds2 = Date.parse(end);
  const diffTime = miliseconds2 - miliseconds1;
  if (diffTime < 0) {
    return 'Diferença negativa';
  }
  const hours = Math.floor(diffTime / (60 * 60 * 1000));
  const hoursms = diffTime % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));

  return `${hours}h${minutes}m`;
}

export function calculateRoundedHoursDifference(
  firstDate: string | Date,
  secondDate: string | Date
): number {
  const start = new Date(firstDate).getTime();
  const end = new Date(secondDate).getTime();

  if (Number.isNaN(start) || Number.isNaN(end)) {
    throw new Error('Uma ou ambas as datas são inválidas.');
  }

  const differenceInMilliseconds = Math.abs(end - start);
  return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60));
}

export function downloadFile(file: string, filename: string) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
}

export function createTableColumnsFilters(data: Array<any>, param: string) {
  return removeDuplicateFromObjectArray(
    data.map((item) => {
      return {
        text: item[param],
        value: item[param],
      };
    }),
    'value'
  ).filter((item) => item.value !== null) as unknown as ColumnFilterItem[];
}

export function translateMessageWithList(
  message: string,
  translatedBaseMessage: string,
  items: { item: string; translatedItem: string }[]
) {
  const messageItems = message
    .split(':')[1]
    .split(',')
    .map((item) => item.replace(' ', ''));
  const messageTranslatedItems = messageItems.map((messageItem) => {
    return items.find((item) => item.item === messageItem)?.translatedItem;
  });
  return `${translatedBaseMessage} ${messageTranslatedItems.join(', ')}`;
}
